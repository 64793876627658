import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";

import Spinner from '../../../Spinner';

import '../../../../assets/css/apiData.css';

import { DASHBOARD_REVENUE_EVENT } from '../../../../actions/types';
import { whiteLabel } from '../../../../config';
const store =  require('../../../../reducers/index');

class DashBoardRevenueComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      revenue: undefined,
      originalRevenue: undefined,
      loading: true,
      socket: false,
      windowWidth: 0
    };
  }

  subscribeFunction = null;

  handleResize = () => {
    if (this.state.revenue && window.innerWidth < 980) {
      let revenue = this.state.originalRevenue.filter(rev => rev.revenue > 0);
      if (revenue.length === 0) {//no box vith revenue
        revenue = this.state.originalRevenue.slice(0, 1);
      }
      this.setState({
        revenue: revenue
      });
    } else if (this.state.revenue && window.innerWidth > 980) {
      const revenue = this.state.originalRevenue;
      this.setState({
        revenue: revenue
      });
    }
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    this.handleResize();

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().dashboardReducer;

      if (state.update === "LOADING") {
        this.setState({
          loading: true
        });
      }

      let revenue = undefined;
      if (state.revenue) {
        revenue = state.revenue.map(elem => Object.assign(elem, { isOpened: false }));
      }

      if (state.update === DASHBOARD_REVENUE_EVENT && this.props.thisMonth && state.revenue.socket) {
        this.setState({
          revenue: revenue,
          originalRevenue: revenue,
          loading: false,
          socket: state.revenue.socket
        }, () => {
          this.handleResize();
        });
        return;
      }

      if (state.update === DASHBOARD_REVENUE_EVENT && !state.revenue.socket) {
        this.setState({
          revenue: revenue,
          originalRevenue: revenue,
          loading: false,
          socket: state.revenue.socket
        }, () => {
          this.handleResize();
        });
      }
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  //Radius = 45
  //Percentage to fill = percents
  //Circumference = 2 * π * Radius = 282
  //Stroke length = (Circumference / 100) * Percentage to fill
  getStrokeLength = (percents) => {
    const circumference = 2 * Math.PI * 45;
    return `${(circumference / 100) * percents}, ${circumference}`;
  };

  // Red = 0% - 25.99%
  // Orange = 26% - 50.99%
  // Yellow = 51% - 75.99%
  // Green = 76% - 100%
  defineColor = (percents) => {
    if (percents < 26) {
      return "#f9483d";
    } else if (percents < 51) {
      return "#ff993a";
    } else if (percents < 76) {
      return "#ffd73a";
    } else {
      return "#b9fa14";
    }
  };

  calculateRevenueWidth = (value) => {
    const { windowWidth } = this.state;
    if (windowWidth > 900) {
      return "30px";
    } else if (windowWidth < 500) {
      return value > 10000000 ? "20px" : value > 1000000000 ? "17px" : "26px";
    }

    return value > 10000000 ? "25px" : value > 1000000000 ? "22px" : "30px";
  };

  render() {
    const { revenue, loading } = this.state;

    let revenuesWrap = <Panel>
      <Panel.Heading>
        <Panel.Title> KEY PERFORMING INDICATORS </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <Spinner />
      </Panel.Body>
    </Panel>;

    if (!loading && revenue) {
      revenuesWrap = (
        <Panel>
          <Panel.Heading>
            <Panel.Title> KEY PERFORMING INDICATORS </Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <div className="panel-content">
              <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end'>
                {revenue.map((r, i) =>
                  <Column key={ i } horizontal='start' vertical='start' className="revenue-boxes-wrap" alignSelf='start'>
                    <Column horizontal='start' vertical='start' className ="revenueBoxes">
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="info">
                          <p style={ { margin: "0 0 0 18px", fontSize: "16px" } }>
                            {r.currency} Payments
                          </p>
                          <h3 className="currency-numbers" style={ { fontSize: this.calculateRevenueWidth(r.revenue) } }>
                            {r.currencySymbol} {Number(r.revenue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </h3>
                          <p style={ { margin: "0 0 0 18px" } }>
                        Net Revenue
                          </p>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' horizontal="center" className="chart-div">
                          <div className="grid">
                            <section>
                              <svg className="circle-chart" viewBox="0 0 100 100" width="100" height="100">
                                <circle className="circle-chart__background" stroke={ whiteLabel === "trustbucks" ? "#4c6c2f" : whiteLabel === "t365" ? "#6b8ba1" : "#99afeb" } strokeWidth="10" fill="none" cx="50" cy="50" r="45" />
                                { r.performance && <circle className="circle-chart__circle"
                                  stroke={ this.defineColor(r.performance) }
                                  transform="rotate(-90) translate(-100 0)"
                                  strokeWidth="10" strokeDasharray={ this.getStrokeLength(r.performance) } strokeLinecap="round" fill="none" cx="50" cy="50" r="45" /> }
                                <g className="circle-chart__info">
                                  <text className="circle-chart__percent" fill="#FFFFFF" x="50" y="35" alignmentBaseline="central" textAnchor="middle" fontSize="25">{r.performance}%</text>
                                  <text className="circle-chart__subline" x="50" y="55" fill={ whiteLabel === "trustbucks" ? "#4c6c2f" : whiteLabel === "t365" ? "#6b8ba1" : "#99afeb" } alignmentBaseline="central" textAnchor="middle" fontSize="13"> of last</text>
                                  <text className="circle-chart__subline" x="50" y="65" fill={ whiteLabel === "trustbucks" ? "#4c6c2f" : whiteLabel === "t365" ? "#6b8ba1" : "#99afeb" } alignmentBaseline="central" textAnchor="middle" fontSize="13"> month</text>
                                </g>
                              </svg>
                            </section>
                          </div>
                        </Column>
                      </Row>
                    </Column>
                  </Column>
                )}
              </Row>
            </div>
          </Panel.Body>
        </Panel>
      );
    }
    return revenuesWrap;
  }

}

export default DashBoardRevenueComponent;